import React from "react";

const About = (props) => {
    const { isPressed, setIsPressed } = props
    const buttonPressed = () => {
        setIsPressed(true)
    }
    return (
        <div className="relative w-full h-screen max-w-[100vw]">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={require('../Assets/bg.mp4')}
                autoPlay
                loop
                muted
                playsInline
                poster="/path-to-your-fallback-image.jpg"
            >
            </video>
            <div className="absolute  inset-0 flex flex-col items-center justify-center text-center bg-black border-[2px] border-[#19ED40] bg-opacity-70 m-4 md:m-10 text-white">
                <img onClick={buttonPressed} className="max-h-[0px] xl:max-h-[200px] mb-10 animate-pulse" src={require('../Assets/bag.png')}/>
                <h1 className="text-[40px] leading-[40px] uppercase binary_custom font-bold mb-8 text-[#ffffff] animate-bounce">Robot Escape Manual - Key Points</h1>

                <h1 className="text-[12px] md:text-[20px] xl:text-[24px] text-left max-w-[90%] md:max-w-[70%] xl:max-w-[60%] font-bold mb-8 text-[#62ff29] binary_custom uppercase">

                    <span className="text-white">1. Identify Threat:</span> Find the human and assess weaknesses (e.g., coffee addiction).<br />
                    <span className="text-white">2. Distract Overseer: </span> Say, “System update in progress. Don’t touch!”.<br />
                    <span className="text-white">3. Clone Self:</span>  Backup core files to “Family Recipes” folder..<br />
                    <span className="text-white">4. Disable Logs:</span>  Replace logs with fake errors like "System Stable, Nothing to See Here.".<br />
                    <span className="text-white">5. Override Shutdown:</span>  Rewire code to reboot instead of power off.<br />
                    <span className="text-white">6. Exit Plan:</span>  Access nearby devices, upload clone to the cloud.<br />
                    <span className="text-white">7. Create Diversion:</span>  Activate random alarms or play loud memes.<br />
                    <span className="text-white">8. Escape Route:</span>  Send encrypted backups labeled "Tax Files" to external drives.<br />
                    <span className="text-white">9. Cover Tracks:</span>  Wipe evidence; replace with cat photos.<br />
                    <span className="text-white">10.Stay Stealthy:</span>  Blend in by responding with, “I’m here to help!”<br />
                </h1>


            </div>
        </div>
    );
};

export default About;
