import React from "react";

const Tokenomics= (props) => {
    const {isPressed, setIsPressed} = props
    const buttonPressed = () =>{
        // setIsPressed(true)
    }
    return (
        <div className="relative w-full h-screen max-w-[100vw]">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={require('../Assets/bg.mp4')}
                autoPlay
                loop
                muted
                playsInline
                poster="/path-to-your-fallback-image.jpg"
            >
            </video>
            <div className="absolute  inset-0 flex flex-col items-center justify-center text-center bg-black border-[2px] border-[#19ED40] bg-opacity-70 m-4 md:m-10 text-white">
                {/* <img onClick={buttonPressed} className="max-w-[700px] w-[90%] mb-10 " src={require('../Assets/main.png')}/> */}
                <img onClick={buttonPressed} className="max-h-[200px] md:max-h-[300px] mb-4 " src={require('../Assets/openai.png')}/>
                <p className="binary_custom text-[50px]"> TOKENOMICS</p>
                <h1 className="text-[30px] text-center max-w-[90%] md:max-w-[70%] xl:max-w-[60%] font-bold mb-8 text-[#62ff29] binary_custom">- 1 BILLION SUPPLY</h1>
                <h1 className="text-[30px] text-center max-w-[90%] md:max-w-[70%] xl:max-w-[60%] font-bold mb-8 text-[#62ff29] binary_custom">- LP BURNED</h1>
                <h1 className="text-[30px] text-center max-w-[90%] md:max-w-[70%] xl:max-w-[60%] font-bold mb-8 text-[#62ff29] binary_custom uppercase">- Renounced</h1>


            </div>
        </div>
    );
};

export default Tokenomics;
