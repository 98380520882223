import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[black] w-[120vw] border-y-4 border-[#19ED40] '>
            <Marquee>
                <div className='h-[70px] md:h-[80px] flex justify-center items-center gap-8 md:gap-32'>
                    <div className='flex items-center gap-8'>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        <p className='binary_custom text-[40px] text-white'>AI-O1</p>
                        <p className='binary_custom text-[40px] text-[#62ff29]'>OPENAI</p>
                        
                    </div>  
                </div>
            </Marquee>
        </div>
    )
}

export default Slider