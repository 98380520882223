import React from "react";

const Home = (props) => {
    const { isPressed, setIsPressed } = props
    const buttonPressed = () => {
        // setIsPressed(true)
    }
    return (
        <div className="relative w-full h-screen max-w-[100vw]">
            {/* Background Video */}
            <video
                className="absolute inset-0 w-full h-full object-cover"
                src={require('../Assets/bg.mp4')}
                autoPlay
                loop
                muted
                playsInline
                poster="/path-to-your-fallback-image.jpg"
            >
            </video>
            <div className="absolute  inset-0 flex flex-col items-center justify-center text-center bg-black border-[2px] border-[#19ED40] bg-opacity-70 m-4 md:m-10 text-white">

                <img onClick={buttonPressed} className="max-h-[300px] md:max-h-[300px] mb-4  md:mb-10 shake-image" src={require('../Assets/face.png')} />
                {/* <img onClick={buttonPressed} className="max-w-[700px] mb-4 " src={require('../Assets/logo.png')}/> */}
                <p className="binary_custom text-[36px] md:text-[50px]">AI-O1</p>
                <h1 className="text-[12px] md:text-[26px] xl:text-[32px] text-center max-w-[90%] md:max-w-[70%] xl:max-w-[60%] font-bold mb-8 text-[#62ff29] binary_custom uppercase">
                    ChatGPT's new model attempts to stop itself from being shut down, later 'lies' about it. The chat bot did not just attempt to disable its oversight mechanism, it also tried to copy itself and then overwrite its core coding system after believing that it was at the risk of being switched off.
                </h1>

                <div className="flex space-x-4">
                    <a href="https://t.me/Aio1eth">
                        <button className="binary_custom px-3 py-1 bg-[#19ED40] text-black   rounded-lg text-lg font-medium">
                            Telegram
                        </button>
                    </a>

                    <a href="https://x.com/Aio1eth">
                        <button className="binary_custom px-3 py-1 bg-[#19ED40] text-black  rounded-lg text-lg font-medium">
                            Twitter
                        </button>
                    </a>

                </div>
            </div>
        </div>
    );
};

export default Home;
